var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "addproject mt-5"
  }, [_c('r-section', {
    attrs: {
      "label": _vm.$t('projects.createProjects.title')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-form', {
          ref: "form",
          staticClass: "mt-5",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('div', [_vm.fetchCreateProjectError ? _c('v-alert', {
          attrs: {
            "dense": "",
            "dismissible": "",
            "tile": "",
            "text": "",
            "color": "red",
            "close-label": "clear",
            "icon": "error",
            "type": "warning",
            "elevation": "0"
          }
        }, [_c('small', [_vm._v(_vm._s(_vm.fetchCreateProjectError))])]) : _vm._e()], 1), _c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "",
            "md": "6",
            "lg": "4"
          }
        }, [_c('r-text-field', {
          staticClass: "input-text addproject--name",
          attrs: {
            "value": _vm.name,
            "label": _vm.$t('projects.name.label'),
            "placeholder": _vm.$t('projects.name.placeholder'),
            "rules": [_vm.rules.required]
          },
          on: {
            "change": _vm.onChangeProjectName
          }
        })], 1)], 1), _c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('r-text-field', {
          staticClass: "input-text addproject--description",
          attrs: {
            "value": _vm.description,
            "label": _vm.$t('projects.description.label'),
            "placeholder": _vm.$t('projects.description.placeholder'),
            "type": "text-area"
          },
          on: {
            "change": _vm.onChangeProjectDescription
          }
        })], 1)], 1), _c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-end"
        }, [_c('r-button', {
          staticClass: "mr-2 addproject--cancel",
          attrs: {
            "color": "normal",
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.onClickCancel
          }
        }), _c('r-button', {
          staticClass: "addproject--save",
          attrs: {
            "label": _vm.$t('global.actions.save'),
            "loading": _vm.fetchingCreateProject
          },
          on: {
            "click": _vm.onClickCreateProject
          }
        })], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }